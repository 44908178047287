<div class="input-group" *ngIf="property?.hide === false && !property?.showPopupButton">
    <mat-form-field [color]="property.isWarn ? 'warn': 'primary'" *ngIf="property | isStringFilter"
                    attr.data-cy="filter-input-string-{{property.Key}}"
                    [ngClass]="property.isValid ? 'valid' : 'invalid'"
                    [appearance]="appearance ?? 'fill'">
        <mat-label>{{property.displayKey ? property.Key : ''}}</mat-label>
        <span matPrefix *ngIf="property.prefix">{{property.prefix}}</span>
        <span matPrefix *ngIf="property.hasIcon"><i class="{{property.iconClass}}"></i></span>
        <input matInput [disabled]="disabled ? disabled : null"
               [(ngModel)]="property.Value" type="{{inputType}}" id="{{property.Key}}"/>
        <span matSuffix *ngIf="property.suffix">{{property.suffix}}</span>
        <mat-hint  *ngIf="!property.validationError">{{property.hint}}</mat-hint>
    </mat-form-field>

    <ng-container *ngIf="property | isMultilineTextFilter">
        <app-multiline-text
            [property]="property"
            [appearance]="appearance"
            (valueChanged)="valueChanged.emit()"
            (keyDownEvent)="keyDownEvent.emit($event)"
        >
        </app-multiline-text>
    </ng-container>

    <mat-form-field [color]="property.isWarn ? 'warn': 'primary'" *ngIf="property | isNumberFilter"
                    attr.data-cy="filter-input-number-{{property.Key}}"
                    [ngClass]="property.isValid ? 'valid' : 'invalid'">
        <span matPrefix *ngIf="property.prefix">{{property.prefix}}</span>
        <span matPrefix *ngIf="property.hasIcon"><i class="{{property.iconClass}}"></i></span>
        <mat-label>{{property.displayKey ? property.Key : ''}}</mat-label>
        <input matInput #inputFilter
               [disabled]="disabled ? disabled : null"
               [ngModel]="property.Value | string | noComma | number"
               [ngModelOptions]="{updateOn:'change'}"
               (ngModelChange)="property.Value=parseFloat(removeCommasFromValue($event))"
               type="text"
               id="{{property.Key}}"
        />
        <span matSuffix *ngIf="property.suffix">{{property.suffix}}</span>
        <mat-hint *ngIf="!property.validationError">{{property.hint}}</mat-hint>
    </mat-form-field>

    <ng-container *ngIf="property | isBooleanFilter">
        <ng-container *ngIf="!property.isSlider">
            <mat-checkbox
                [(ngModel)]="property.Value"
                disabled="{{property.isReadonly}}"
                (click)="$event.stopPropagation(); valueChanged.emit()"
                [matTooltipDisabled]="!property.isReadonly"
                [matTooltip]="property.disabledTooltip"
                matTooltipClass="tooltip"
            >
                {{property.displayKey ? property.Key : ''}}</mat-checkbox>
            <mat-hint  *ngIf="!property.validationError">{{property.hint}}</mat-hint>
        </ng-container>
        <ng-container *ngIf="property.isSlider">
            <mat-slide-toggle
                [(ngModel)]="property.Value"
                disabled="{{property.isReadonly}}"
                [labelPosition]="'before'"
                [matTooltipDisabled]="!property.isReadonly"
                [matTooltip]="property.disabledTooltip"
                [hideIcon]="true"
                matTooltipClass="tooltip"
            >
                {{property.displayKey ? property.Key : ''}}</mat-slide-toggle>
            <mat-hint  *ngIf="!property.validationError">{{property.hint}}</mat-hint>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="property | isDateFilter">
        <app-date-select
            [icon]="property.iconClass"
            [isEndDate]="property.isEndDate"
            [property]="property"
            [dateView]="property.view"
            [disabled]="disabled ? disabled : null"
            (valueChanged)="valueChanged.emit()"
        >
        </app-date-select>
    </ng-container>

    <ng-container *ngIf="property | isTimeFilter">
        <app-time-select
            [icon]="property.iconClass"
            [property]="property"
            [disabled]="disabled ? disabled : null"
            [val]="property.Value"
        >
        </app-time-select>
    </ng-container>


    <app-date-range-select *ngIf="property | isDateRangeFilter" [icon]="property.iconClass" [property]="property"
                           [startDate]="property.Value?.startDate" [endDate]="property.Value?.endDate"
                           [disabled]="disabled ? disabled : null">
    </app-date-range-select>

    <app-autocomplete
        *ngIf="property | isAutocompleteFilter"
        [property]="property"
        [overrideDisabled]="overrideDisabled"
    >

    </app-autocomplete>

    <ng-container *ngIf="(property | isSelectFilter) || (property | isMultiselectFilter) || (property | isPoliticalWindowsConfigTierFilter)">
        <div class="inline-form-options">
            <mat-button-toggle-group
                *ngIf="property.isButtons"
                attr.data-cy="filter-input-buttons-{{property.Key}}"
                [(ngModel)]="property.Value"
                [multiple]="property | isMultiselectFilter"
                id="{{property.Key}}"
                [disabled]="disabled ? disabled : null"
            >
                <mat-button-toggle
                    attr.data-cy="filter-input-buttons-{{property.Key}}-{{option}}"
                    *ngFor="let option of property.filteredOptions | async; let i = index"
                    [value]="option"
                    [ngStyle]="{ display : !property.partialButtons || i < property.partialButtons ? '' : 'none' }"
                >
                    {{option | displayText}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-form-field [color]="property.isWarn ? 'warn': 'primary'"
                [ngClass]="[property.isValid ? 'valid' : 'invalid', (property.filteredOptions | async)?.length > property.partialButtons ? 'select-filter-with-buttons' : '']"
                attr.data-cy="filter-input-select-{{property.Key}}"
                class="select-filter"
                *ngIf="((property | isSelectFilter) || (property | isMultiselectFilter) || (property | isPoliticalWindowsConfigTierFilter)) && (!property.isButtons || (property.filteredOptions | async)?.length > property.partialButtons)"
                (keydown)="keyPress($event)">
                <span matPrefix *ngIf="(!property.isLoaded || property.isLoading)"><img src="assets/images/loader.svg"/></span>
                <span matPrefix
                      *ngIf="property.hasIcon && ((property.isLoaded && !property.isLoading) || !property.isAsync)">
                <i class="{{property.iconClass}}"></i>
            </span>
                <app-filter-input
                    *ngIf="property.booleanFilter"
                    attr.data-cy="filter-input-boolean-{{property.Key}}"
                    [property]="property.booleanFilter"
                    (valueChanged)="valueChanged.emit($event)"
                    class="select-filter-checkbox"
                ></app-filter-input>
                <div class="add-custom-daypart">
                    <a
                        *ngIf="property?.customButton"
                        attr.data-cy="filter-input-custom-button-{{property.Key}}"
                        (click)="property?.customCallback(); $event.stopPropagation()"
                        class="button is-small is-primary pencil-icon"
                        [title]="property?.customTitle"
                    >
                        <i class="fas fa-pencil-alt" class="fas fa-pencil-alt"></i>
                    </a>
                </div>
                <mat-label
                >{{property.displayKey ? property.defaultName + ' ' + property.Key : ''}}
                </mat-label>
                <mat-select
                    [panelClass]="'ag-custom-component-popup select-overlay'"
                    [panelWidth]="null"
                    [multiple]="property | isMultiselectFilter"
                    #selectFilter
                    id="{{property.Key}}"
                    [(ngModel)]="property.Value"
                    [aria-label]="property.defaultName + ' '  + property.Key"
                    [disabled]="disabled ? disabled : null"
                        [compareWith]="property.compareFn"
                    (opened)="openedChange()"
                    (closed)="closedChange()"
                >
                    <mat-select-trigger
                        *ngIf="(property | isMultiselectFilter) && property.Value">{{ (property.Value?.length > property.triggerLength && property.triggerText) || property.Value | displayText }}</mat-select-trigger>
                    <mat-select-trigger
                        *ngIf="!(property | isMultiselectFilter) && property.Value">{{ property.Value | displayText }}</mat-select-trigger>
                    <div class="select-container">
                        <ng-container *ngIf="property.isFilterable">
                            <!-- this mat option makes it so the filter will open even if there are no selectable options -->
                            <mat-option style="display: none"></mat-option>
                                <mat-form-field [color]="property.isWarn ? 'warn': 'primary'" class="width-100-pct">
                                    <input #search
                                           attr.data-cy="filter-input-search-field-{{property.Key}}"
                                           autocomplete="off"
                                           placeholder="Search"
                                           aria-label="Search"
                                           matInput
                                           [formControl]="searchTextboxControl"
                                           (keydown)="keyPress($event)">
                                    <button [disableRipple]="true"
                                            attr.data-cy="filter-input-search-button-{{property.Key}}"
                                            *ngIf="search.value"
                                            matSuffix
                                            mat-icon-button
                                            class="is-flex justify-center"
                                            aria-label="Clear" (click)="clearSearch()">
                                        <i class="fas fa-times" style="font-size:1.5rem"></i>
                                    </button>
                                </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="property | isMultiselectFilter">
                            <mat-checkbox *ngIf="property.canSelectAll"
                                          attr.data-cy="filter-input-select-all-{{property.Key}}"
                                          class="mat-option"
                                          [(checked)]="property.isAllSelected"
                                          [(indeterminate)]="property.isSubsetSelected"
                                          (change)="selectAll($event)">Select All
                            </mat-checkbox>
                        </ng-container>
                        <mat-option *ngIf="property.callbackDisplayText"
                                    attr.data-cy="filter-input-callback-{{property.Key}}"
                                    class="callback-option"
                                    (click)="property.callbackOption()">{{property.callbackDisplayText}}</mat-option>
                        <mat-option *ngIf="property.isClearable">None</mat-option>
                        <ng-container *ngIf="((property.filteredOptions | async))?.length; else emptySearchText">
                            <!-- TODO: Figure out a way to get this to work without breaking de-selection -->
                            <!--                        <ng-container-->
                            <!--                            *ngIf="((Property.filteredOptions | async) || [])!.length > 100000">-->
                            <!--                            &lt;!&ndash; inline styling because height MUST be itemSize * 6&ndash;&gt;-->
                            <!--                            <cdk-virtual-scroll-viewport class="overflowX-hidden" [orientation]="'vertical'"-->
                            <!--                                                         [itemSize]="30" [style.height.px]="6*30" [minBufferPx]="400" [maxBufferPx]="1000">-->
                            <!--                                <ng-container *ngIf="Property.Type === 'Select'">-->
                            <!--                                    <mat-option (onSelectionChange)="selectionChange($event)"-->
                            <!--                                                *cdkVirtualFor="let option of Property.filteredOptions | async"-->
                            <!--                                                [value]="option"-->
                            <!--                                                [innerHtml]="option | displayText">-->
                            <!--                                    </mat-option>-->
                            <!--                                </ng-container>-->
                            <!--                                <ng-container *ngIf="Property.Type === 'MultiSelect'">-->
                            <!--                                    <mat-option (onSelectionChange)="selectionChange($event)"-->
                            <!--                                                *cdkVirtualFor="let option of Property.filteredOptions | async"-->
                            <!--                                                [value]="option">-->
                            <!--                                        {{option | displayText}}-->
                            <!--                                    </mat-option>-->
                            <!--                                </ng-container>-->
                            <!--                            </cdk-virtual-scroll-viewport>-->
                            <!--                        </ng-container>-->
                            <ng-container>
                                <!--                       <ng-container-->
                                <!--                            *ngIf="((Property.filteredOptions | async) || [])!.length <= 100000"-->
                                <!--                        >-->

                                <ng-container
                                    *ngIf="((property | isPoliticalWindowsConfigTierFilter) && !property.showTierGroup) || (property | isSelectFilter)">
                                    <mat-option (onSelectionChange)="selectionChange($event)"
                                                attr.data-cy="filter-input-select-option"
                                                *ngFor="let option of property.filteredOptions | async; let i = index"
                                                [value]="option"
                                                [disabled]="disabledOptions?.includes(option)"
                                                [ngStyle]="{ display : !property.partialButtons || i >= property.partialButtons ? '' : 'none' }"
                                    >
                                        {{option | displayText}}
                                    </mat-option>
                                </ng-container>

                                <ng-container
                                    *ngIf="(property | isPoliticalWindowsConfigTierFilter) && property.showTierGroup">
                                    <mat-optgroup label="*Political" *ngIf="getPoliticalOptions(property.filteredOptions | async).length > 0">
                                        <mat-option (onSelectionChange)="selectionChange($event)"
                                                    attr.data-cy="filter-input-select-option"
                                                    *ngFor="let option of getPoliticalOptions(property.filteredOptions | async); let i = index"
                                                    [value]="option"
                                        >
                                            {{option | displayText}}
                                        </mat-option>
                                    </mat-optgroup>
                                    <mat-optgroup label="*Dynamic">
                                        <mat-option (onSelectionChange)="selectionChange($event)"
                                                    attr.data-cy="filter-input-select-option"
                                                    *ngFor="let option of getDynamicOptions(property.filteredOptions | async); let i = index"
                                                    [value]="option"
                                        >
                                            {{option | displayText}}
                                        </mat-option>
                                    </mat-optgroup>
                                    <mat-optgroup label="*PCode"
                                                  *ngIf="checkPCodeTierAvailability(property.filteredOptions | async) && property.showPcodes">
                                        <mat-option (onSelectionChange)="selectionChange($event)"
                                                    attr.data-cy="filter-input-select-option"
                                                    *ngFor="let option of getPCodeOptions(property.filteredOptions | async); let i = index"
                                                    [value]="option"
                                        >
                                            {{option | displayText}}
                                        </mat-option>
                                    </mat-optgroup>
                                </ng-container>

                                <ng-container *ngIf="property | isMultiselectFilter">
                                    <div *ngIf="!property.optionsDeletable">
                                        <mat-option (onSelectionChange)="selectionChange($event)"
                                                    attr.data-cy="filter-input-multiselect-option-{{property.Key}}-{{option}}"
                                                    [value]="option"
                                                    [disabled]="disabledOptions?.includes(option)"
                                                    [ngClass]="property.highlightOptions?.includes(option.id) ? 'highlight' : ''"
                                                    *ngFor="let option of property.filteredOptions | async; let i = index"
                                                    [ngStyle]="{ display : !property.partialButtons || i >= property.partialButtons ? '' : 'none' }">
                                            {{option | displayText}}
                                        </mat-option>
                                    </div>
                                    <div *ngIf="property.optionsDeletable">
                                        <div class="deletable-option"
                                             *ngFor="let option of property.filteredOptions | async; let i = index">
                                            <mat-option (onSelectionChange)="selectionChange($event)"
                                                        class="option-checkbox"
                                                        attr.data-cy="filter-input-multiselect-option-{{property.Key}}-{{option}}"
                                                        [value]="option"
                                                        [disabled]="disabledOptions?.includes(option)"
                                                        [ngClass]="property.highlightOptions?.includes(option.id) ? 'highlight' : ''"
                                                        [ngStyle]="{ display : !property.partialButtons || i >= property.partialButtons ? '' : 'none' }">
                                                {{option | displayText}}
                                            </mat-option>
                                            <a *ngIf="!option.isGlobal"
                                               (click)="deleteItem(option)">
                            <span class="icon margin-left-half-em margin-right-half-em saved-options-icon">
                                <i class="fa-2x margin-left-half-em fas fa-trash-alt" aria-hidden="true"></i>
                            </span>
                                            </a>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <a *ngIf="property.showCreatePoliticalWindow"
                           class="button is-primary create-political-window-custom-width"
                           (click)="property.createPoliticalWindow()">
                            <i class="fa-plus margin-right-half-em"></i>
                            {{property?.createPoliticalWindowDisplayText}}
                        </a>
                    </div>
                </mat-select>
                <mat-hint  *ngIf="!property.validationError">{{property.hint}}</mat-hint>
            </mat-form-field>
        </div>
    </ng-container>

    <ng-template #emptySearchText>
        <ng-container *ngIf="(property | isSelectFilter) || (property | isMultiselectFilter)">
            <mat-optgroup
                *ngIf="((property.filteredOptions | async) === null || ((property.filteredOptions | async))?.length === 0) && !isSearching; else searching">
                <div>{{searchTextboxControl.value ? 'No Results Found!' : property.emptySearchText}}</div>
            </mat-optgroup>
        </ng-container>
    </ng-template>

    <ng-template #searching>
        <mat-optgroup>
            <div>Searching...</div>
        </mat-optgroup>
    </ng-template>


    <div *ngIf="(property | isRadioFilter)" class="columns is-vcentered">
        <strong
            *ngIf="property.displayKey"
            class="column is-2 has-text-right">{{property.displayKey ? property.Key + ':' : ''}}
        </strong>
        <span matPrefix *ngIf="property.isLoading"><img src="assets/images/loader.svg"/></span>
        <span matPrefix *ngIf="property.hasIcon && (property.isLoaded || !property.isAsync)"><i
            class="{{property.iconClass}}"></i></span>
        <mat-radio-group class="column"
                         attr.data-cy="filter-input-radio-{{property.Key}}"
                         id="{{property.Key}}"
                         [(ngModel)]="property.Value"
                         [disabled]="disabled ? disabled : null">
            <div class="select-container">
                <mat-radio-button (onSelectionChange)="selectionChange($event)"
                                  attr.data-cy="filter-input-radio-option-{{property.Key}}-{{option}}"
                                  *ngFor="let option of property.filteredOptions | async"
                                  [value]="option"
                                  [disabled]="disabledOptions?.includes(option)">
                    {{option | displayText}}
                </mat-radio-button>
            </div>
        </mat-radio-group>
        <mat-hint  *ngIf="!property.validationError">{{property.hint}}</mat-hint>
    </div>

    <div *ngIf="(property | isCheckboxFilter)"
         attr.data-cy="filter-input-checkbox-{{property.Key}}"
         class="is-flex is-vcentered checkbox-filter">
        <strong class="is-1 has-text-right">{{property.displayKey ? property.Key + ':' : ''}}</strong>
        <span matPrefix *ngIf="property.isLoading"><img src="assets/images/loader.svg"/></span>
        <span matPrefix *ngIf="property.hasIcon && (property.isLoaded || !property.isAsync)"><i
            class="{{property.iconClass}}"></i></span>
        <mat-checkbox *ngFor="let option of property.filteredOptions | async"
                      attr.data-cy="filter-input-checkbox-option-{{property.Key}}-{{option}}"
                      [(ngModel)]="option.included"
                      (change)="valueChanged.emit()"
                      [disabled]="disabled ? disabled : null">
            {{option | displayText}}
        </mat-checkbox>
        <mat-hint  *ngIf="!property.validationError">{{property.hint}}</mat-hint>
    </div>

    <div *ngIf="!property.isValid && property.validationError" class="invalid error-message">
        {{property.validationError}}
    </div>
</div>
<div class="input-group"
     attr.data-cy="filter-input-popup-button-{{property.Key}}"
     *ngIf="property?.showPopupButton">
    <div
        class="button is-primary"
        [title]="property.buttonTitle"
        [attr.disabled]="property.buttonIsDisabled"
        (click)="property.buttonOnClick()"
    >{{property.buttonLabel}}</div>
</div>
