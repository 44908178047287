import {
    BooleanFilter,
    CheckboxFilter,
    DateFilter,
    FilterTemplate,
    MultiSelectFilter,
    NumberFilter,
    RadioFilter,
    SelectFilter,
    StringFilter,
    TimeFilter,
} from '@models/filter-types';
import {
    BooleanLookupItem,
    ChannelItem,
    DaypartLookupItem,
    LookupItem,
    MarketItem, MonthYear, PCodeChannelItem, PCodeMarketItem, PCodeStationItem,
    Period,
    MetricsData,
    PricingTier,
    RateCardPeriod, ReportItem, SavedReport,
    Quarter,
    StationItem,
    PriorityCodeTiersQuarter,
    SavedReportInfoFromPopUp,
} from '@models/lookup';
import { dateFormatter, isSingleOptionSelected } from '@shared/helpers/functions/helpers';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@services/notification/notification.service';
import { loadSavedValuesIntoFiltersAsync } from '@shared/helpers/functions/filter-helpers';
import { forkJoin, of } from 'rxjs';
import { LoadReportPopupComponent } from '@shared/components/report-components/load-report-popup/load-report-popup.component';
import { SaveReportPopupComponent } from '@shared/components/report-components/save-report-popup/save-report-popup.component';
import { FilterOption } from './rate-card/rate-card';
import { UrlStore } from '@shared/helpers/constants/url-store';
import moment from 'moment';
import { AdvertiserInsightsDependentFiltersResponse } from '@models/advertiser-insights/advertiser-insights';
import { AdvertiserInsightsFilters } from '@models/advertiser-insights/advertiser-insights-filters';
import { FilterSettings } from '@models/filter';
import { AdminService } from '@services/admin/admin.service';
import { defaultIfEmpty } from 'rxjs/operators';
import { DynamicRateCardFiltersV2 } from '@models/rate-card/dynamic-rate-card-filters-v2';
import { PoliticalRateCardFiltersV2 } from '@models/rate-card/political-rate-card-filters-v2';
import { DigitalRateCardFilters } from '@models/rate-card/digital-rate-card-filters';



export const channelGroupFilter: (settings: FilterSettings) => SelectFilter<LookupItem> =
    (settings: FilterSettings) => {
        const filter: SelectFilter<LookupItem> = new SelectFilter<LookupItem>(
            settings.config.customerCapability.rate_optics.general.station
                ? (settings.config.customerConfig.station || 'Station') + ' Group'
                : 'Channel Group',
        )
            .Defer(() => settings.lookupSvc.getChannelGroups(
                false,
                settings.hasDynamicRates,
                settings.hasPoliticalRates,
                settings.hasDigitalRates,
                settings.tabNumber,
            ))
            .Transform((item: LookupItem) => item?.id)
            .Required(true)
            .IsClearable(false)
            .Clear(() => filter.Value = null)
            .Icon('fas fa-object-group');
        return filter;
    };

export const channelGroupsFilter: (settings: FilterSettings) => MultiSelectFilter<LookupItem> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<LookupItem> = new MultiSelectFilter<LookupItem>(
            settings.config.customerCapability.rate_optics.general.station
                ? (settings.config.customerConfig.station || 'Station') + ' Groups'
                : 'Channel Groups',
        )
            .Defer(() => settings.lookupSvc.getChannelGroups(
                false,
                settings.hasDynamicRates,
                settings.hasPoliticalRates,
                settings.hasDigitalRates,
                settings.tabNumber,
            ))
            .Transform(groups => groups.map(group => group.id))
            .Required(true)
            .IsClearable(false)
            .Clear(() => filter.Value = [])
            .Icon('fas fa-object-group');
        return filter;
    };

export const marketsFilter: (settings: FilterSettings) => MultiSelectFilter<MarketItem> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<MarketItem> = new MultiSelectFilter<MarketItem>('Markets')
            .Defer(() => settings.lookupSvc.getMarkets(false,
                                                       settings.hasDynamicRates,
                                                       settings.hasPoliticalRates,
                                                       settings.hasDigitalRates,
                                                       settings.tabNumber))
            .Transform((items: MarketItem[]) => items.map(item => item.id))
            .Filterable(true)
            .Required(true)
            .Toggle(!settings.config?.roleCapability?.rate_optics.general.markets)
            .Icon('fas fa-map-marked-alt');
        return filter;
    };

export const pCodeMarketsFilter: (settings: FilterSettings) => MultiSelectFilter<PCodeMarketItem> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<PCodeMarketItem> = new MultiSelectFilter<PCodeMarketItem>('Markets')
            .Defer(() => settings.lookupSvc.getPCodeMarkets(
                false,
                settings.hasDynamicRates,
            ))
            .Transform((items: PCodeMarketItem[]) => items.map(item => item?.id))
            .Filterable(true)
            .Required(true)
            .Toggle(!settings.config.roleCapability.rate_optics.general.markets)
            .Icon('fas fa-map-marked-alt');
        return filter;
    };

export const stationsFilter: (settings: FilterSettings) => MultiSelectFilter<StationItem> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<StationItem> = new MultiSelectFilter<StationItem>((settings.config.customerConfig.station || 'Station')  + 's')
            .Defer(() => settings.lookupSvc.getStations(
                false,
                settings.hasDynamicRates,
                settings.hasPoliticalRates,
                settings.hasDigitalRates,
                settings.tabNumber,
            ))
            .Transform((items: StationItem[]) => items.map(item => item.id))
            .Filterable(true)
            .Required(true)
            .Toggle(!settings.config.roleCapability.rate_optics.general.station)
            .Icon('fas fa-tower-cell');
        return filter;
    };


export const channelsFilterSingleSelect: (settings: FilterSettings) => SelectFilter<ChannelItem> =
(settings: FilterSettings) => {
    const filter: SelectFilter<ChannelItem> = new SelectFilter<ChannelItem>((settings.config.customerConfig.channel || 'Channel'))
        .Defer(() => settings.lookupSvc.getChannels(
            false,
            settings.hasDynamicRates,
            settings.hasPoliticalRates,
            settings.hasDigitalRates,
            settings.tabNumber,
        ))
        .Transform((item: ChannelItem) => item.id)
        .Filterable(true)
        .Required(true)
        .Toggle(!settings.config.roleCapability.rate_optics.general.channel)
        .Icon('fas fa-network-wired');
    return filter;
};

export const stationsFilterSingleSelect: (settings: FilterSettings) => SelectFilter<StationItem> =
    (settings: FilterSettings) => {
        const filter: SelectFilter<StationItem> = new SelectFilter<StationItem>((settings.config.customerConfig.station || 'Station')  + 's')
            .Defer(() => settings.lookupSvc.getStations(
                false,
                settings.hasDynamicRates,
                settings.hasPoliticalRates,
                settings.hasDigitalRates,
                settings.tabNumber,
            ))
            .Transform((item: StationItem) => item.id)
            .Filterable(true)
            .Required(true)
            .Toggle(!settings.config.roleCapability.rate_optics.general.station)
            .Icon('fas fa-tower-cell');
        return filter;
    };

export const quarterFilterSingleSelect: () => SelectFilter<MonthYear> =
    () => {
        const filter: SelectFilter<MonthYear> = new SelectFilter<MonthYear>('Quarter')
            .Transform((item: MonthYear) => item?.id)
            .Filterable(true)
            .OnLoad((items: MonthYear[]) => {
                const today = new Date();
                const quarterNumber = Math.ceil((today.getMonth() + 1) / 3); //Months starts at 0 for January
                const year = today.getFullYear();
                const quarterName = 'Quarter ' + quarterNumber.toString() + ' ' + year.toString();
                if (items.length > 0) {
                    filter.Value = items.find(item => item.name === quarterName);
                }
            })
            .Required(true)
            .Icon('fas fa-calendar-alt');
        return filter;
    };

export const pCodeStationsFilter: (settings: FilterSettings) => MultiSelectFilter<PCodeStationItem> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<PCodeStationItem> = new MultiSelectFilter<PCodeStationItem>((settings.config.customerConfig.station || 'Station')  + 's')
            .Defer(() => settings.lookupSvc.getPCodeStations(false, settings.hasDynamicRates))
            .Transform((items: PCodeStationItem[]) => items.map(item => item?.id))
            .Filterable(true)
            .Required(true)
            .Toggle(!settings.config.roleCapability.rate_optics.general.station)
            .Icon('fas fa-tower-cell');
        return filter;
    };

export const channelsFilter: (settings: FilterSettings) => MultiSelectFilter<ChannelItem> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<ChannelItem> = new MultiSelectFilter<ChannelItem>('Channels')
            .Defer(() => settings.lookupSvc.getChannels(
                false,
                settings.hasDynamicRates,
                settings.hasPoliticalRates,
                settings.hasDigitalRates,
                settings.tabNumber,
                settings.hasDashboardData,
            ))
            .Filterable(true)
            .Transform((items: ChannelItem[]) => items.map(item => item.id))
            .Toggle(!settings.config?.roleCapability?.rate_optics?.general?.channel)
            .Required(true)
            .Icon('fas fa-network-wired');
        return filter;
    };

export const pCodeChannelsFilter: (settings: FilterSettings) => MultiSelectFilter<PCodeChannelItem> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<PCodeChannelItem> = new MultiSelectFilter<PCodeChannelItem>('Channels')
            .Defer(() => settings.lookupSvc.getPCodeChannels(false, settings.hasDynamicRates))
            .Filterable(true)
            .Transform((items: PCodeChannelItem[]) => items.map(item => item?.id))
            .Toggle(!settings.config?.roleCapability?.rate_optics?.general?.channel)
            .Required(true)
            .Icon('fas fa-network-wired');
        return filter;
    };

export const periodFilter: (settings: FilterSettings) => SelectFilter<Period> =
    (settings: FilterSettings) => {
        const filter: SelectFilter<Period> = new SelectFilter<Period>('Period')
            .Defer(() => settings.lookupSvc.getPeriods(settings.screenName, settings.tabNumber, null))
            .Transform(v => ({ id: v?.id, name: v?.name }))
            .Filterable(true)
            .RestoreWithId(false)
            .Clear(() => filter.Value = filter.options.find(option => option.isDefault) ||
            filter.options.find(option => option.name === 'Week') || filter.options[0])
            .Icon('fas fa-chart-column');
        return filter;
    };

export const quarterFilter: (settings: FilterSettings) => SelectFilter<Quarter> =
    (settings: FilterSettings) => {
        const filter: SelectFilter<Quarter> = new SelectFilter<Quarter>('Quarter')
            .Icon('fas fa-chart-column')
            .EmptySearchText('Load data to see quarter options')
            .Filterable(true);
        return filter;
    };

export const pcodeQuarterFilter: (settings: FilterSettings) => MultiSelectFilter<PriorityCodeTiersQuarter> =
    (settings: FilterSettings) => {
        const filter: MultiSelectFilter<PriorityCodeTiersQuarter> = new MultiSelectFilter<PriorityCodeTiersQuarter>('Quarter')
            .Transform(items => items.map(item => item.name))
            .Icon('fas fa-chart-column')
            .EmptySearchText('Load data to see quarter options')
            .Filterable(true);
        return filter;
    };

export const startDateFilter: () => DateFilter = () => {
    const filter: DateFilter = new DateFilter('Start Date')
        .Icon('fas fa-calendar-plus')
        .View('Week');
    return filter;
};

export const endDateFilter: () => DateFilter = () => {
    const filter: DateFilter = new DateFilter('End Date')
        .Icon('fas fa-calendar-minus')
        .View('Week')
        .IsEndDate(true);
    return filter;
};

export const startTimeFilter: (key?: string) => TimeFilter = (key = 'Start Time') => {
    const filter: TimeFilter = new TimeFilter(key)
        .Icon('fas fa-clock-plus');
    return filter;
};

export const endTimeFilter: (key?: string) => TimeFilter = (key = 'End Time') => {
    const filter: TimeFilter = new TimeFilter(key)
        .Icon('fas fa-clock-plus');
    return filter;
};

export const rateFilter: (min: number, max: number, spotLength: number, tier: string) => NumberFilter = (min, max, spotLength, tier) => {
    const filter: NumberFilter = new NumberFilter(`Rate (${spotLength ? spotLength + 's' : ''} ${tier || ''})`).Required(true).Min(min).Max(max);
    return filter;
};

export const daypartsFilter: (settings: FilterSettings) => MultiSelectFilter<DaypartLookupItem> = (settings: FilterSettings) => {
    const filter: MultiSelectFilter<DaypartLookupItem> = new MultiSelectFilter<DaypartLookupItem>('Dayparts')
        .CompareFn((l, r) => isSingleOptionSelected(l, r))
        .Transform(items => items.map(daypart => daypart.id))
        .Icon('fas fa-calendar-day')
        .Filterable(true)
        .Toggle(false)
        .Required(true);
    return filter;
};

export const daypartsSingleFilter: (settings: FilterSettings) => SelectFilter<DaypartLookupItem> = (settings: FilterSettings) => {
    const filter: SelectFilter<DaypartLookupItem> = new SelectFilter<DaypartLookupItem>('Daypart')
        .CompareFn((l, r) => isSingleOptionSelected(l, r))
        .Transform(item => item.id)
        .Icon('fas fa-calendar-day')
        .Filterable(true)
        .Toggle(false)
        .Required(true);
    return filter;
};

export const spotLengthsFilter: () => MultiSelectFilter<LookupItem> = () => {
    const filter: MultiSelectFilter<LookupItem> = new MultiSelectFilter<LookupItem>('Spot Lengths')
        .Transform(spotLengths => spotLengths.map(spotLength => spotLength.id))
        .Icon('fas fa-stopwatch')
        .Required(true);
    return filter;
};

export const spotTypesFilter: () => MultiSelectFilter<LookupItem> = () => {
    const filter: MultiSelectFilter<LookupItem> = new MultiSelectFilter<LookupItem>('Spot Type')
        .Icon('fas fa-bullseye')
        .Filterable(true)
        .Transform(spotTypes => spotTypes.map(spotType => spotType.id))
        .Required(true);
    return filter;
};

export const dowsFilter: () => CheckboxFilter<BooleanLookupItem> = () => {
    const filter: CheckboxFilter<BooleanLookupItem> = new CheckboxFilter<BooleanLookupItem>('DOWs')
        .Required(true)
        .DisplayKey(false)
        .Transform(_ => filter.options.filter(dow => dow.included).map(dow => dow.id));
    return filter;
};

export const pricingTiersFilter: () => MultiSelectFilter<PricingTier> = () => {
    const filter: MultiSelectFilter<PricingTier> = new MultiSelectFilter<PricingTier>('Pricing Tiers')
        .CompareFn((l, r) => l.id === r.id)
        .Transform(v => v.map(val => val.id))
        .Icon('fas fa-gift')
        .Filterable(true)
        .Clear(() => filter.Value = filter.options);
    return filter;
};

export const politicalTiersFilter: () => MultiSelectFilter<LookupItem> = () => {
    const filter: MultiSelectFilter<LookupItem> = new MultiSelectFilter<LookupItem>('Political Tiers')
        .CompareFn((l, r) => l.id === r.id)
        .Transform(v => v.map(val => val.id))
        .Icon('fas fa-gift')
        .Filterable(true)
        .Clear(() => filter.Value = filter.options);
    return filter;
};

export const flightWeeksFilter: (resetFlightWeeks: () => void) => MultiSelectFilter<string> = (resetFlightWeeks: () => void) => {
    const filter: MultiSelectFilter<string> = new MultiSelectFilter<string>('Flight Weeks')
        .Defer(() => of([]))
        .OnLoad((items: string[]) => {
            resetFlightWeeks();
        })
        .Clear(() => filter.Value = filter.options)
        .TriggerLength(3)
        .Transform((v: string[]) => v.map(week => dateFormatter(week, 'yyyy/MM/dd')))
        .RestoreWithId(false)
        .Icon('fas fa-paper-plane');
    return filter;
};

export const productsFilter: () => MultiSelectFilter<LookupItem> = () => {
    const filter: MultiSelectFilter<LookupItem> = new MultiSelectFilter<LookupItem>('Products')
        .Transform(products => products.map(product => product.id))
        .Required(true)
        .IsClearable(false)
        .Clear(() => filter.Value = [])
        .Icon('fas fa-barcode');
    return filter;
};

export const productTypesFilter: () => MultiSelectFilter<LookupItem> = () => {
    const filter: MultiSelectFilter<LookupItem> = new MultiSelectFilter<LookupItem>('Product Types')
        .Transform(products => products.map(product => product.id))
        .Required(true)
        .IsClearable(false)
        .Clear(() => filter.Value = [])
        .Icon('fas fa-barcode');
    return filter;
};

export const moduleNamesFilter: (adminSvc: AdminService, screenName: string) => SelectFilter<LookupItem> =
    (adminSvc: AdminService, screenName: string) => {
        const filter: SelectFilter<LookupItem> = new SelectFilter<LookupItem>('Module')
            .Defer(() => adminSvc.getModules(screenName))
            .Transform((item: LookupItem) => item?.id)
            .Required(false)
            .IsClearable(false)
            .Clear(() => filter.Value = null)
            .Icon('fas fa-object-group');
        return filter;
    };

export interface ReportData {
    savedMetrics: string[];
    savedSegments: string[];
    startDate: string;
    endDate: string;
    global: boolean;
    favorited: boolean;
    useRollingDates: boolean;
    rollingPeriod: string;
    rollingPeriodNumber: number;
    metricsOrder: string[];
    segmentsOrder: string[];
}

export const saveReport = <T>(
    matDialog: MatDialog,
    url: string,
    filter: FilterTemplate,
    lookupSvc: LookupV2Service,
    snackBarSvc: NotificationService,
    metricsData: MetricsData,
    segmentsKey: string,
    segmentsOrderKey: string,
    includeQuickDataSelect: boolean,
    includeDateRange: boolean,
    filterJSON: T,
    filtersCheck: boolean,
    tabData: number = 1,
    /* eslint-disable max-params */
    resetEndDate: boolean = true,
): void => {
    /* eslint-enable max-params */
    const newOrExistingFilter = new RadioFilter<FilterOption>('')
        .DisplayKey(false)
        .Options([
            {
                name: 'Create New',
                filterFunc: () => true,
            },
            {
                name: 'Update Existing',
                filterFunc: () => true,
            },
        ]);
    newOrExistingFilter.Value = newOrExistingFilter.options[0];

    const selectExistingReportFilter = new SelectFilter<ReportItem>('Report');
    let existingReports: SavedReport[]=[];

    const updateFilters = (items: ReportItem) => {
        saveAsGlobalFilter.Value = items.isGlobalReport;

        if (items.isRollingDates) {
            dateRangeOptionsFilter.Value = dateRangeOptionsFilter.options[1];
            periodSelectFilter.Value = periodSelectFilter.options.find(i => i.name === items.rollingPeriod);
            numberOfPeriodsFilter.Value = items.rollingPeriodNumber;
        } else {
            dateRangeOptionsFilter.Value = dateRangeOptionsFilter.options[0];
        }
    };

    lookupSvc.loadSavedReports(url, tabData).subscribe(
        (result: SavedReport[]) => {
            existingReports = result;
            selectExistingReportFilter.Options(result.map(report => ({
                id: report.reportId,
                name: report.reportName,
                isGlobalReport: report.isGlobal,
                isRollingDates: report.isRollingDates,
                rollingPeriod: report.filter?.rolling_period,
                rollingPeriodNumber: report.filter?.rolling_period_number,
            })));
        },
        error => {
            console.error(error);
        },
    );

    selectExistingReportFilter.OnChange(updateFilters);

    const nameFilter = new StringFilter('Report Name')
        .Validator(val => {
            if (val === null || existingReports.filter(report => report.reportName.toUpperCase() === val.toUpperCase()).length>0) {
                return false;
            } else{
                return true;
            }
        }, () => `Name Already Exists ${nameFilter.value}`)
        .Required(true);

    const saveMetricSelectionsFilter = new BooleanFilter('Save Metric Selections', true)
        .Slider(true);

    const saveAsGlobalFilter = new BooleanFilter('Save as Global', false)
        .Slider(true).Toggle(!filter.config.roleCapability.rate_optics.general.create_global_report);

    const numberOfPeriodsFilter = new NumberFilter('Number of Periods')
        .Min(1)
        .Required(true);

    const periodSelectFilter = new SelectFilter<Period>('Period')
        .OnChange((period: Period) => {
            const periodName = period?.name ? period.name + 's': 'Periods';
            numberOfPeriodsFilter.Key = `Number of ${periodName}`;
        });
    lookupSvc.getPeriods(url).subscribe(
        res => {
            periodSelectFilter.Options(res);
            if(url === UrlStore.screenNames.politicalLur || url === UrlStore.screenNames.rateGenerator){
                periodSelectFilter.Options(periodSelectFilter.options.filter(option => option.name === 'Week'));
            }
        },
        error => {
            console.error(error);
        },
    );

    const quickDateOptionsSelectFilter = new SelectFilter<LookupItem>('Quick Date Options').Options(
        [{ id: 1, name: 'Current Quarter', displayName: 'Current Quarter' },
            { id: 2, name: 'Next Quarter', displayName: 'Next Quarter' },
            { id: 3, name: 'Current Year', displayName: 'Current Year' },
            { id: 4, name: 'Next Year', displayName: 'Next Year' }],
    );

    const radioOptions=[];


    radioOptions.push({
        name: 'Save Fixed Dates',
        filterFunc: () => true,
    });

    if(includeDateRange){
        radioOptions.push({
            name: 'Save Rolling Dates',
            filterFunc: () => true,
        });
        periodSelectFilter.Value=periodSelectFilter.options.find(option => option.name === 'Week');
    }
    //Hide QuickDate Select until the Quick Date options filter is ready to go to PROD
    if(includeQuickDataSelect){
        // radioOptions.push({
        //     name: 'Quick Date Options',
        //     filterFunc: () => true
        // });
    }
    const dateRangeOptionsFilter = new RadioFilter<FilterOption>('')
        .DisplayKey(false)
        .Options(radioOptions);
    dateRangeOptionsFilter.Value = dateRangeOptionsFilter.options[0];
    const addAsFavoriteFilter = new BooleanFilter('Set as Favorite', false)
        .Slider(true);

    let rateCardPeriods: RateCardPeriod[];
    lookupSvc.getRateCardPeriods(moment(new Date()).format('YYYY-MM-DD')).subscribe(
        res => {
            rateCardPeriods=res;
        },
        error => {
            console.error(error);
            snackBarSvc.openSnackBar('Failed to read periods', 'error');
        },
    );
    const showMetrics = filter.showMetrics;
    const dialog = matDialog.open(SaveReportPopupComponent, {
        width: '25em',
        data: {
            filters: {
                newOrExistingFilter,
                selectExistingReportFilter,
                nameFilter,
                saveMetricSelectionsFilter,
                saveAsGlobalFilter,
                dateRangeOptionsFilter,
                periodSelectFilter,
                quickDateOptionsSelectFilter,
                numberOfPeriodsFilter,
                addAsFavoriteFilter,
                showMetrics,
            },
            allFiltersSelectedCheck: filtersCheck,
        },
    });

    dialog.afterClosed().subscribe(filterData => {
        if (filterData) {
            const data = {} as ReportData;
            const dateRangeFilterSelected = filterData.dateRangeOptionsFilter.Value.name;
            data.useRollingDates = dateRangeFilterSelected === 'Save Rolling Dates';
            data.global = filterData.saveAsGlobalFilter.Value;
            data.favorited = filterData.addAsFavoriteFilter.Value;
            const additionalMetricsKey = metricsData?.additionalMetricsKey;
            const additionalMetrics = additionalMetricsKey ? lookupSvc.getFilterFromLocalStorage(additionalMetricsKey): [];
            data.savedMetrics = additionalMetrics.concat(lookupSvc.getFilterFromLocalStorage(metricsData.metricsKey));
            data.metricsOrder = lookupSvc.getFilterFromLocalStorage(metricsData.metricsOrderKey);
            data.savedSegments = lookupSvc.getFilterFromLocalStorage(segmentsKey);
            data.segmentsOrder = lookupSvc.getFilterFromLocalStorage(segmentsOrderKey);
            if (dateRangeFilterSelected === 'Save Rolling Dates') {
                data.rollingPeriod = filterData.periodSelectFilter.Value.name;
                data.rollingPeriodNumber = Number(filterData.numberOfPeriodsFilter.Value);
                data.startDate = null;
                data.endDate = null;
            } else {
                if (dateRangeFilterSelected === 'Save Fixed Dates') {
                    data.startDate = filter.startDate?.Value.format('YYYY-MM-DD HH:mm:ss');
                    data.endDate = filter.endDate?.Value.format('YYYY-MM-DD HH:mm:ss');
                }
                if(dateRangeFilterSelected === 'Quick Date Options') {
                    const periodName = filterData.quickDateOptionsSelectFilter.Value.name;
                    const quarterPeriodId = 3;
                    const yearPeriodId = 4;
                    const isQuarter = periodName.includes('Quarter');
                    const isCurrent = periodName.includes('Current');
                    const qPeriodId = isQuarter ? quarterPeriodId : yearPeriodId;
                    const qPeriod=rateCardPeriods.find(rcPeriod=>rcPeriod?.periodId === qPeriodId);
                    data.startDate=moment(qPeriod.periodStartDate,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
                    if (isCurrent) {
                        data.endDate=moment(qPeriod.periodEndDate,'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        const unit = isQuarter ? 'quarter' : 'year';
                        data.endDate=moment(qPeriod.periodEndDate,'YYYY-MM-DD').add(1,unit).format('YYYY-MM-DD HH:mm:ss');
                    }
                }
                if(resetEndDate){
                    data.endDate=moment(data.endDate,'YYYY-MM-DD HH:mm:ss').day(0).format('YYYY-MM-DD HH:mm:ss');
                }
                // TODO: Fix this as a part of https://revenueanalytics.atlassian.net/browse/RO-7788
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                if('startDate' in filterJSON && filterJSON.startDate){
                    filterJSON.startDate=moment(data.startDate,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                }
                // @ts-ignore
                if(filterJSON.endDate){
                    // @ts-ignore
                    filterJSON.endDate=moment(data.endDate,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                }
            }
            if(UrlStore.rateCardScreens.includes(url)) {
                // @ts-ignore
                filterJSON.showMetrics = filter.showMetrics;
                // @ts-ignore
                filterJSON.tierValue = filter.tierValue;
                // @ts-ignore
                filterJSON.primaryRateValue = filter.primaryRateValue;
                // @ts-ignore
                filterJSON.alertFilter = filter.alertFilter;
                // @ts-ignore
                filterJSON.quarterlyPCode=filter.quarterlyPCode;
                // @ts-ignore
                filterJSON.quarterlyPoliticalSummary=filter.quarterlyPoliticalSummary;
                // @ts-ignore
                if(filter.totals){
                    // @ts-ignore
                    filterJSON.totals=filter.totals;
                }
            }
            /* eslint-enable @typescript-eslint/ban-ts-comment */

            callSaveOrUpdateReport(data, filterData, filterJSON, filter, url, tabData, lookupSvc, snackBarSvc);
        }
    });
};

export const saveReportDashBoard: (
    matDialog: MatDialog,
    url: string,
    filter: FilterTemplate,
    lookupSvc: LookupV2Service,
    snackBarSvc: NotificationService,
    metricsData: unknown,
    includeQuickDataSelect: boolean,
    includeDateRange: boolean,
    filterJSON: unknown,
    filtersCheck: boolean,
    tabData?: number
) => void = (
    matDialog: MatDialog,
    url: string,
    filter: FilterTemplate,
    lookupSvc: LookupV2Service,
    snackBarSvc: NotificationService,
    metricsData: { metricsKey: string; metricsOrderKey: string },
    includeQuickDataSelect: boolean,
    includeDateRange: boolean,
    filterJSON: unknown,
    filtersCheck: boolean,
    tabData: number = 1,
) => {
    const newOrExistingFilter = new RadioFilter<FilterOption>('')
        .DisplayKey(false)
        .Options([
            {
                name: 'Create New',
                filterFunc: () => true,
            },
            {
                name: 'Update Existing',
                filterFunc: () => true,
            },
        ]);
    newOrExistingFilter.Value = newOrExistingFilter.options[0];
    let existingReports=[];

    const updateFilters = (items: ReportItem) => {
        saveAsGlobalFilter.Value = items.isGlobalReport;
    };
    const selectExistingReportFilter = new SelectFilter<LookupItem>('Report');
    lookupSvc.loadSavedReports(url, tabData).subscribe(
        result => {
            existingReports = result;
            selectExistingReportFilter.Options(result.map(report => ({
                id: report.reportId,
                name: report.reportName,
                isGlobalReport: report.isGlobal,
            })));
        },
        error => {
            console.error(error);
        },
    );

    selectExistingReportFilter.OnChange(updateFilters);

    const nameFilter = new StringFilter('Report Name')
        .Validator(val => {
            if (val === null || existingReports.filter(report => report.reportName.toUpperCase() === val.toUpperCase()).length>0) {
                return false;
            } else{
                return true;
            }
        }, () => `Name Already Exists ${nameFilter.value}`)
        .Required(true);
    const saveMetricSelectionsFilter = new BooleanFilter('Save Metric Selections', true)
        .Slider(true);
    const saveAsGlobalFilter = new BooleanFilter('Save as Global', false)
        .Slider(true).Toggle(!filter.config.roleCapability.rate_optics.general.create_global_report);
    const addAsFavoriteFilter = new BooleanFilter('Set as a Favorite', false)
        .Slider(true);

    const dialog = matDialog.open(SaveReportPopupComponent, {
        data: {
            filters: {
                nameFilter,
                newOrExistingFilter,
                selectExistingReportFilter,
                saveMetricSelectionsFilter,
                saveAsGlobalFilter,
                addAsFavoriteFilter,
            },
            allFiltersSelectedCheck: filtersCheck,
        },
        width: '25em',
    });

    dialog.afterClosed().subscribe(filterData => {
        if (filterData) {
            const data = {} as ReportData;
            if (filterData.saveMetricSelectionsFilter.Value) {
                data.savedMetrics = lookupSvc.getFilterFromLocalStorage(metricsData.metricsKey);
                data.metricsOrder = lookupSvc.getFilterFromLocalStorage(metricsData.metricsOrderKey);
            }
            data.global = filterData.saveAsGlobalFilter.Value;
            data.favorited = filterData.addAsFavoriteFilter.Value;

            callSaveOrUpdateReport(data, filterData, filterJSON, filter, url, tabData, lookupSvc, snackBarSvc);

        }
    });
};

export const callSaveOrUpdateReport: (data, filterData, filterJSON, filter, url, tab, lookupSvc, snackBarSvc)
        => void = (data, filterData, filterJSON, filter, url, tab, lookupSvc, snackBarSvc) => {
            let selectedChannels: LookupItem[];
            const channelsSelected = filter.channels?.value || [filter.channelsSingleSelect.Value.id];
            if(channelsSelected.length >0 && isNaN(channelsSelected[0])){
                selectedChannels = channelsSelected.map(channel=>channel.id);
            }else{
                selectedChannels=channelsSelected;
            }
            const stringChannels: string = selectedChannels.join(',');
            const isNewReport = filterData.newOrExistingFilter.Value.name === 'Create New';
            const reportName = isNewReport ? filterData.nameFilter.value : filterData.selectExistingReportFilter.Value.name;
            const reportId = isNewReport ? null : filterData.selectExistingReportFilter.Value.id;
            lookupSvc.saveReport(url, tab, data, filterJSON, stringChannels, reportName, isNewReport, reportId).subscribe(
                () => {
                    const type = isNewReport ? 'Saved' : 'Updated';
                    snackBarSvc.openSnackBar(type + ' report successfully', 'success');
                },
                error => {
                    const type = isNewReport ? 'save' : 'update';
                    console.error(error);
                    snackBarSvc.openSnackBar('Failed to ' + type + ' report', 'error');
                },
            );
        };

export const loadReport: (matDialog: MatDialog, url: string, filter: FilterTemplate, lookupSvc: LookupV2Service,
                              metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string,
                              afterLoad: () => void, tabData?: number, tabId?: string)
        => void = (matDialog: MatDialog, url: string, filter: FilterTemplate, lookupSvc: LookupV2Service,
                   metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string,
                   afterLoad: () => void, tabData: number = 1, tabId?: string) => {
            lookupSvc.loadSavedReports(url, tabData).subscribe(
                reports => {
                    const dialog = matDialog.open(LoadReportPopupComponent, {
                        data: {
                            url,
                            filter,
                            reports,
                            ...(tabId && { tabId }),
                        },
                    });
                    dialog.afterClosed().subscribe((savedFilter) => {
                        loadReportIntoFilters(
                            savedFilter, filter, lookupSvc, metricsKey, metricsOrderKey, segmentsKey, segmentsOrderKey, afterLoad,
                        );
                    });
                },
            );
        };

export const loadReportIntoFilters: (savedFilter: SavedReportInfoFromPopUp, filter: FilterTemplate, lookupSvc: LookupV2Service,
    metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string, afterLoad: () => void) => void=
    (savedFilter: SavedReportInfoFromPopUp, filter: FilterTemplate, lookupSvc: LookupV2Service,
     metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string, afterLoad: () => void) => {
        if (savedFilter?.filter) {
            filter.savedReportLoaded = true;
            lookupSvc.saveFilterToLocalStorage(metricsKey, savedFilter.filter.savedMetrics);
            lookupSvc.saveFilterToLocalStorage(metricsOrderKey, savedFilter.filter.savedMetricsOrder);
            lookupSvc.saveFilterToLocalStorage(segmentsKey, savedFilter.filter.savedSegments);
            lookupSvc.saveFilterToLocalStorage(segmentsOrderKey, savedFilter.filter.savedSegmentsOrder);

            if (filter instanceof AdvertiserInsightsFilters) {
                loadSavedValuesIntoFiltersAsync(filter, savedFilter.filter).subscribe(response => {
                    const cascadingFilterData = filter.getCascadingFilterData(filter.advertisers.name,filter.advertisers.Value);
                    filter.advertiserInsightsSvc.getCascadingFilters(cascadingFilterData)
                        .subscribe((data: AdvertiserInsightsDependentFiltersResponse) => {
                            const throwError = filter.shouldThrowNoDataWarning(data);
                            if (throwError) {
                                filter.noDataAvailableWarning();
                            }
                            filter.updateSavedReportCascadingFilters(data, savedFilter.filter);
                            filter.savedReportLoaded = false;
                            afterLoad();
                        },
                                   error => {
                                       filter.failedToLoadFilters(filter.advertisers.name, error);
                                   });
                });
            } else {


                if ('channelGroup' in savedFilter.filter) {
                    // TODO: Fix this as a part of https://revenueanalytics.atlassian.net/browse/RO-7788
                    /* eslint-disable @typescript-eslint/ban-ts-comment */
                    filter.channelGroup?.Default(filter.channelGroup.options.find(
                        // @ts-ignore
                        i => i.id === savedFilter.filter.channelGroup,
                    ));
                    // @ts-ignore
                } else if (filter.channels?.options.length > 0) { // If filters already have channels loaded
                    if ('channels' in savedFilter.filter && savedFilter.filter.channels === 'ALL') {

                        // Default to channel group from first channel in dropdown
                        // @ts-ignore
                        const channelGroup = filter.channels.options[0].channelGroup;
                        // @ts-ignore
                        filter.channelGroup?.Default(filter.channelGroup.options.find(i => i.id === channelGroup));
                    } else if ('channels' in savedFilter.filter && Array.isArray(savedFilter.filter.channels) && savedFilter.filter.channels.length > 0) {

                        // Find first channel in saved report, map it to pre existing filter, and use that channel group
                        const firstChannel = savedFilter.filter.channels[0];
                        // @ts-ignore
                        const firstChannelGroup = filter.channels.options.find(i => i.id === firstChannel).channelGroup;
                        // @ts-ignore
                        filter.channelGroup?.Default(filter.channelGroup.options.find(i => i.id === firstChannelGroup));
                    }
                } else { // Last resource, default to first

                    filter.channelGroup?.Default(filter.channelGroup?.options[0]);
                }
                if('showMetrics' in savedFilter.filter){
                    filter.showMetrics = savedFilter.filter.showMetrics as boolean;
                }
                if (isRateCardFilter(filter)) {
                    // @ts-ignore
                    filter.quarterlyPCode = savedFilter.filter.quarterlyPCode;
                    // @ts-ignore
                    filter.quarterlyPoliticalSummary = savedFilter.filter.quarterlyPoliticalSummary;
                    // @ts-ignore
                    filter.totals = savedFilter.filter.totals;
                    // @ts-ignore
                    filter.primaryRateValue = savedFilter.filter.primaryRateValue;
                    // @ts-ignore
                    filter.alertFilter = savedFilter.filter.alertFilter ||  filter.alertFilter;
                    // @ts-ignore
                    filter.tierValue = savedFilter.filter.tierValue || filter.tierValue;
                }
                filter.changeDates = false;
                loadSavedValuesIntoFiltersAsync(filter, savedFilter.filter).subscribe(_ => {
                    if (filter?.dynamicFilters) {
                        // to fix dynamic properties with select all not being passed to load data api.
                        // this is happening due to cascading filter values to child filters.
                        const dynamicProperties = [];
                        Object.keys(filter?.dynamicFilters).map(key => {
                            if (savedFilter.filter[key] === 'ALL') {
                                dynamicProperties.push(filter.dynamicFilters[key]);
                            }
                        });
                        forkJoin(
                            [forkJoin(dynamicProperties.map(prop => prop.Load())).pipe(defaultIfEmpty([]))],
                        ).subscribe(([dynamicPropertiesValueArray]) => {
                            dynamicPropertiesValueArray.forEach((value, index) => {
                                dynamicProperties[index].finishLoad(value);
                            });
                            afterLoad();
                        });
                    } else {
                        afterLoad();
                    }
                });
            }
            /* eslint-enable @typescript-eslint/ban-ts-comment */
        }
    };

export const isRateCardFilter = (filter): filter is
    DynamicRateCardFiltersV2 |
    PoliticalRateCardFiltersV2 |
    DigitalRateCardFilters => filter instanceof DynamicRateCardFiltersV2 ||
           filter instanceof PoliticalRateCardFiltersV2 ||
        filter instanceof DigitalRateCardFilters;

export const loadSavedReport: (reportId: number, filter: FilterTemplate, lookupSvc: LookupV2Service, snackBar: NotificationService,
metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string, afterLoad: () => void) => void = (
    reportId: number, filter: FilterTemplate, lookupSvc: LookupV2Service, snackBar: NotificationService,
    metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string, afterLoad: () => void,
) => {
    lookupSvc.getSavedReport(reportId).subscribe(
        reports => {
            const savedReport=reports[0];
            if(savedReport && savedReport.filter){
                if ('channels' in savedReport && savedReport.channels.length === 0){
                    snackBar.openSnackBar(`You do not presently have access to data for the ${filter.config.customerConfig.station || 'station'}(s) contained in this report. Please contact your RateOptics Admin to request access.`, 'error');
                }else{
                    const savedFilter = savedReport.filter;
                    savedFilter.savedMetrics = savedReport.savedMetrics.savedMetrics;
                    savedFilter.savedMetricsOrder = savedReport.savedMetrics.savedMetricsOrder;
                    savedFilter.savedSegments = savedReport.savedSegments.savedSegments;
                    savedFilter.savedSegmentsOrder = savedReport.savedSegments.savedSegmentsOrder;
                    loadReportIntoFilters({ filter: savedFilter, selectedReport: savedReport },
                                          filter,
                                          lookupSvc,
                                          metricsKey,
                                          metricsOrderKey,
                                          segmentsKey,
                                          segmentsOrderKey,
                                          afterLoad.bind(this));
                }
            }else{
                snackBar.openSnackBar('No Access to the report', 'error');
            }
        },
    );
};

export const loadSharedReport: (sharedReportId: string, filter: FilterTemplate, lookupSvc: LookupV2Service, snackBar: NotificationService,
metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string, afterLoad: () => void) => void = (
    sharedReportId: string, filter: FilterTemplate, lookupSvc: LookupV2Service, snackBar: NotificationService,
    metricsKey: string, metricsOrderKey: string, segmentsKey: string, segmentsOrderKey: string, afterLoad: () => void,
) => {
    lookupSvc.getSharedReport(sharedReportId).subscribe(
        reports => {
            const savedReport=reports[0];
            if(savedReport && savedReport.filter){
                if ('channels' in savedReport && savedReport.channels.length === 0){
                    snackBar.openSnackBar(`You do not presently have access to data for the ${filter.config.customerConfig.station || 'station'}(s) contained in this report. Please contact your RateOptics Admin to request access.`, 'error');
                }else{
                    const savedFilter = savedReport.filter;
                    savedFilter.savedMetrics = savedReport.savedMetrics.savedMetrics;
                    savedFilter.savedMetricsOrder = savedReport.savedMetrics.savedMetricsOrder;
                    savedFilter.savedSegments = savedReport.savedSegments.savedSegments;
                    savedFilter.savedSegmentsOrder = savedReport.savedSegments.savedSegmentsOrder;
                    loadReportIntoFilters({ filter: savedFilter, selectedReport: savedReport },
                                          filter,
                                          lookupSvc,
                                          metricsKey,
                                          metricsOrderKey,
                                          segmentsKey,
                                          segmentsOrderKey,
                                          afterLoad.bind(this));
                }
            }else{
                snackBar.openSnackBar('No Access to the report', 'error');
            }
        },
        error => {
            snackBar.openSnackBar('Invalid report id', 'error');
        },
    );
};

