import { forkJoin, Observable, of } from 'rxjs';
import {
    GroupedGrid,
    GroupedGridColumn,
    PrimaryTierMap,
    PcodePrimaryTierMap,
} from '@models/lookup';
import {
    RateCardMetricAlertCalculations,
    RateCardPricingTier,
    RateCardMetricValueV2,
    RateCardMetricRowV2,
    DynamicRateCardMetricValueV2,
} from '@models/rate-card/rate-card';
import {
    numberFormatter, removeCommasFromValue,
} from '@shared/helpers/functions/helpers';
import { cloneDeep } from 'lodash';
import { switchMap } from 'rxjs/operators';


export const compareValues = (val1: number, comparator: string, val2: number): boolean => {
    if (comparator === '<=') {
        return val1 <= val2;
    } else if (comparator === '<') {
        return val1 < val2;
    } else if (comparator === '=') {
        return val1 === val2;
    } else if (comparator === '>') {
        return val1 > val2;
    } else if (comparator === '>=') {
        return val1 >= val2;
    } else if (comparator === '!=') {
        return val1 !== val2;
    }
    return false;
};

export const calculateRateCardAlerts2 = <T = RateCardPricingTier, U extends object = object>(
    values: RateCardMetricRowV2[],
    alerts: RateCardMetricAlertCalculations[],
    groupedColumns: GroupedGridColumn[],
    primaryTierMap: PrimaryTierMap,
    pcodePrimaryTierMap: PcodePrimaryTierMap,
    tiers: T[],
    copyFunc: (value: RateCardMetricRowV2,
               primaryTierMap: PrimaryTierMap,
               pcodePrimaryTierMap: PcodePrimaryTierMap,
               cell: RateCardMetricValueV2,
               dateTier: string,
               tiers: T[],
               newValues: unknown) => void,
    passThroughParams: U ,
): Observable<GroupedGrid<RateCardMetricRowV2> & { tiers: T[] } & U> => {
    if (values.length === 0) {
        return of({ data: [], groupedColumns, tiers, ...passThroughParams });
    }
    const rcIdStart = {};
    const rcIdEnd = {};
    values.forEach((value, index) => {
        if (rcIdStart[value.rateCardId] === undefined) {
            rcIdStart[value.rateCardId] = index;
        }
        rcIdEnd[value.rateCardId] = index;
    });
    const obsArr = [];
    Object.keys(rcIdStart).forEach(rcID => {
        obsArr.push(calculateAlertsForIdV2(values.slice(rcIdStart[rcID], rcIdEnd[rcID] + 1), alerts, rcID, primaryTierMap));
    });
    return forkJoin(obsArr).pipe(
        switchMap(rcAlerts => {
            const allAlerts = {};
            rcAlerts.forEach(rcAlert => allAlerts[rcAlert[0]] = rcAlert[1]);
            values.forEach(value => {
                value.alerts = {
                    ...(value.alerts || {}),
                    ...(allAlerts[value.rateCardId].alerts || {}),
                };
                const newValues = {};
                Object.keys(value.value).forEach(dateTier => {

                    ' 2025-01-01-T1';

                    const cell = value.value[dateTier];

                    cell.alerts = {
                        ...(cell.alerts || {}),
                        ...(allAlerts[value.rateCardId][dateTier]?.alerts || {}),
                    };
                    copyFunc(
                        value,
                        primaryTierMap,
                        pcodePrimaryTierMap,
                        cell,
                        dateTier,
                        tiers,
                        newValues,
                    );
                    newValues[dateTier] = cell;
                });
                value.value = newValues;
            });
            return of({ data: values, groupedColumns, tiers, ...passThroughParams });
        }),
    );
};

export const dynamicRateCardCopyCell = (
    value: RateCardMetricRowV2<DynamicRateCardMetricValueV2>,
    primaryTierMap: PrimaryTierMap,
    pcodePrimaryTierMap: PcodePrimaryTierMap,
    cell: DynamicRateCardMetricValueV2,
    dateTier: string,
    tiers: RateCardPricingTier[],
    newValues: unknown,
): void => {
    if (value.isTiered && (primaryTierMap || pcodePrimaryTierMap)) {
        if (cell.isPcode) {
            cell.isPrimary = pcodePrimaryTierMap.tierName === cell.pricingTier;
        } else {
            cell.isPrimary = primaryTierMap[value.daypartId][value.spotTypeId] === cell.pricingTier;
        }
    } else if (!value.isTiered) {
        if (cell.isTotal) {
            tiers.forEach(tier => {
                const newCell = cloneDeep(cell);
                const col = dateTier.split('-')[0];
                newCell.pricingTier = tier.name;
                newValues[`${col}-${tier.name}`] = newCell;
            });
        } else {
            const date: string = dateTier.split('-').slice(0, 3).join('-');
            tiers.forEach(tier => {
                const newCell = cloneDeep(cell);
                newCell.pricingTier = tier.name;
                newValues[`${date}-${tier.name}`] = newCell;
            });
        }
    }
};

export const politicalRateCardCopyCell = (
    value: RateCardMetricRowV2,
    primaryTierMap: PrimaryTierMap,
    pcodePrimaryTierMap: PcodePrimaryTierMap,
    cell: RateCardMetricValueV2,
    dateTier: string,
    tiers: RateCardPricingTier[],
    newValues: unknown,
) => {
    if (!value.isTiered) {
        const date: string = dateTier.split('-').slice(0, 3).join('-');
        tiers.forEach(tier => {
            newValues[`${date}-${tier.name}`] = cloneDeep(cell);
        });
    }
};

export const digitalRateCardCopyCell = (
    value: RateCardMetricRowV2<DynamicRateCardMetricValueV2>,
    primaryTierMap: PrimaryTierMap,
    pcodePrimaryTierMap: PcodePrimaryTierMap,
    cell: DynamicRateCardMetricValueV2,
    dateTier: string,
    tiers: RateCardPricingTier[],
    newValues: unknown,
): void => {
    if (value.isTiered) {
        tiers.forEach(tier => {
            if (tier.name === cell.pricingTier) {
                cell.isPrimary = tier.is_primary;
            }
        });
    } else if (!value.isTiered) {
        const date: string = dateTier.split('-').slice(0, 3).join('-');
        tiers.forEach(tier => {
            const newCell = cloneDeep(cell);
            newCell.pricingTier = tier.name;
            newValues[`${date}-${tier.name}`] = newCell;
        });
    }
};

const calculateAlertsForIdV2 = (
    values: RateCardMetricRowV2[],
    alerts: RateCardMetricAlertCalculations[],
    rcID: string,
    primaryTierMap?: PrimaryTierMap,
): Observable<unknown> => {
    const rcAlerts = { alerts: {}};
    values.forEach(value => {
        alerts.filter(alert => alert.primaryMetric === value.metricTypeId)
            .forEach(alert => {
                Object.keys(value.value).forEach(dateTier => {
                    const cell = value.value[dateTier];
                    let primaryDateTier;
                    let primaryTierId;

                    if (rcAlerts[dateTier] === undefined) {
                        rcAlerts[dateTier] = { alerts: {}};
                    }
                    if (primaryTierMap) {
                        primaryTierId = primaryTierMap[value.daypartId][value.spotTypeId];
                        primaryDateTier = dateTier.split('-').slice(0, 3).join('-') + '-' + primaryTierId;
                        if (rcAlerts[primaryDateTier] === undefined) {
                            rcAlerts[primaryDateTier] = { alerts: {}};
                        }
                    }

                    const alertCount = (alertName, isAlert) => {
                        if (value.isTiered) {
                            rcAlerts.alerts[alertName] = (rcAlerts.alerts[alertName] || 0) + (isAlert ? 1 : 0);
                            rcAlerts[dateTier].alerts[alertName] =
                                (rcAlerts[dateTier].alerts[alertName] || 0) + (isAlert ? 1 : 0);
                        } else if (value.isTiered === false) {
                            rcAlerts.alerts[alertName] = (rcAlerts.alerts[alertName] || 0) + (isAlert ? 1 : 0);
                            if (primaryDateTier) {
                                rcAlerts[primaryDateTier].alerts[alertName] =
                                    (rcAlerts[primaryDateTier].alerts[alertName] || 0) + (isAlert ? 1 : 0);
                            }
                            cell.alerts = {
                                ...(cell.alerts || {}),
                                ...{ [alertName]: (isAlert ? 1 : 0) },
                            };
                        } else {
                            cell.alerts = {
                                ...(cell.alerts || {}),
                                ...{ [alertName]: (isAlert ? 1 : 0) },
                            };
                        }
                    };

                    if (cell.value) {
                        const thresholds = alert.thresholds.length ? alert.thresholds : [''];
                        thresholds.forEach(threshold => {
                            const isAlert = doesCellTriggerAlertV2(
                                alert,
                                cell,
                                value,
                                values,
                                rcID,
                                dateTier,
                                threshold,
                            );
                            const alertName = alert.alertId + threshold;
                            alertCount(alertName, isAlert);
                        });

                    }
                });

            });
    });
    return of([rcID, rcAlerts]);
};

const doesCellTriggerAlertV2 = (
    alert: RateCardMetricAlertCalculations,
    cell: RateCardMetricValueV2,
    row: RateCardMetricRowV2,
    values: RateCardMetricRowV2[],
    rcID: string,
    dateTier: string,
    threshold: number,
): boolean => {
    if (cell.isTotal) {
        return false; // never trigger alerts on the totals columns
    }

    // use the number formatter to round the value based on the format
    // This is just for mathematical comparison purposes, so we use number format rather than currency/percent
    const mainRowFormatter = row.format?.format
        ? (value: number) => Number(removeCommasFromValue(numberFormatter(value, row.format.format)))
        : (value: number) => value;
    const primaryValue = alert.useOriginalPrimary ? cell.value?.original : cell.value?.current;
    let isAlert = false;
    if (alert.isApproved) {
        isAlert = cell.overrideData ? cell.overrideData.isApproved === false : false;
    } else if (alert.alertNull) {
        isAlert = primaryValue === null;
    } else if (alert.compareOverride) {
        const formattedOriginal = mainRowFormatter(cell.value.original);
        isAlert = compareValues(
            mainRowFormatter(cell.value.current),
            alert.comparisonOperator,
            formattedOriginal
            + formattedOriginal * threshold / 100 * (alert.negativeThreshold ? -1 : 1),
        );
    } else if (alert.compareRecValue) {
        if (cell.overrideData?.recValue) {
            const formattedRecValue = mainRowFormatter(cell.overrideData.recValue);
            isAlert = compareValues(
                mainRowFormatter(primaryValue),
                alert.comparisonOperator,
                formattedRecValue
                + formattedRecValue * threshold / 100 * (alert.negativeThreshold ? -1 : 1),
            );
        }
    } else if (!alert.secondaryMetric) {
        isAlert = primaryValue != null ? compareValues(mainRowFormatter(primaryValue),
                                                       alert.comparisonOperator,
                                                       threshold) : false;
    } else {
        const secondaryMetricRow = values
            .find(value => value.rateCardId === rcID && value.metricTypeId === alert.secondaryMetric);
        let secondaryValue = null;
        if (secondaryMetricRow) {
            secondaryValue = alert.useOriginalSecondary ? secondaryMetricRow.value[dateTier]?.value.original :
                secondaryMetricRow.value[dateTier]?.value.current;
        }
        if (secondaryValue != null) {
            const secondaryRowFormatter = secondaryMetricRow.format?.format
                ? (value: number) => Number(numberFormatter(value, secondaryMetricRow.format.format))
                : (value: number) => value;
            if (primaryValue && secondaryMetricRow?.value?.[dateTier]?.value &&
                secondaryMetricRow.value[dateTier]?.value.current != null) {
                const formattedSecondaryValue = secondaryRowFormatter(secondaryValue);
                isAlert = compareValues(
                    mainRowFormatter(primaryValue),
                    alert.comparisonOperator,
                    formattedSecondaryValue
                    + formattedSecondaryValue * threshold / 100 * (alert.negativeThreshold ? -1 : 1),
                );
            }
        }
    }
    return isAlert;
};
